import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './Flash_dialogue.css';

function Flash_dialogue({ handleClose }) {

    return (
        <div className="flash_diaground">

            <div className="flashdiaContainer">
                <div className="titleflashdiaCloseBtn">
                    <button onClick={() => { handleClose(false); }}>
                        <ArrowBackIcon />
                    </button>
                    <h3>FLASH REWARD BONUS</h3>
                </div>

                <h3 id='paydocumention'>Complete or Submit 6 GAPS before Dec. 31st and receive a $3000 dollar bonus! - Offer Expires: Dec. 31, 2024</h3>
            </div>

        </div>
    );
}

export default Flash_dialogue;
