import React, { useState, useEffect, createContext, memo, useContext } from 'react'
import { Button } from './Dropdown/Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import MoreOptionsDropdown from './Dropdown/MoreOptionsDropdown';
import { GlobalInfo } from "../../App"

function Navbar() {
  const { loginenter } = useContext(GlobalInfo)

  const [click, setClick] = useState(false);
  const [activeButton, setActiveButton] = useState(null); // New state variable

  const [user_nme, setUser_nme] = React.useState('');
  const [member_id, setMember_id] = React.useState('');
  const [role, setRole] = React.useState('');


  useEffect(() => {
    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const member_id = parsed[0].member_id;
    const user_name = parsed[0].name;
    const role = parsed[0].role;

    setUser_nme(user_name)
    setMember_id(member_id)
    setRole(role)
    /////////////

  }, [])


  const handleClick = () => {
    setClick(!click)

    if (!click) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }
  };

  const closeMobileMenu = (item) => {
    console.log("item", item)
    setClick(false);
    document.body.style.overflow = 'auto';

    if (item.path == '/Log_Out') {
      localStorage.removeItem("customer");
      loginenter()
    }
  }

  const pat = [
    {
      title: 'Home',
      path: '/',
      cName: 'nav-links',
    },
    ...(role === 'admin' ? [
      {
        title: 'All Chat',
        path: '/Chat',
        cName: 'nav-links',
      },
      {
        title: 'Upload Excel',
        path: '/Upload_Xlsx',
        cName: 'nav-links',
      }
    ] : []),
    {
      title: 'Log Out',
      path: '/Log_Out',
      cName: 'nav-links',
    },
    {
      title: 'Delete Account',
      path: '/Delete_Account',
      cName: 'nav-links',
    },
  ];


  const handleButtonClick = (index) => {
    setActiveButton(index);
  };


  return (
    <>
      <nav className='navbar'>

        <div className='menu_icon' onClick={handleClick}>
          <i className={'fas fa-bars'} />
        </div>

        <div className='navbar_logo'>
          {user_nme}
        </div>

        <Button />




        {click &&
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <i onClick={handleClick} className={'fas fa-times'} />


            {pat.map((item, index) => {
              return (
                <li className='nav-item' key={index}>
                  <Link
                    to={item.path}
                    className={activeButton === index ? 'nav-links active' : 'nav-links'}
                    onClick={() => {
                      closeMobileMenu(item);
                      handleButtonClick(index);
                    }}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        }


      </nav>
    </>
  );
}

export default Navbar;
