import React, { useState, useEffect, useContext } from 'react'
import './Home.css';
import { Link, useNavigate } from 'react-router-dom';
import Connection from "../../connection";
import 'react-loading-skeleton/dist/skeleton.css'
import PulseLoader from "react-spinners/PulseLoader";
import Navbar from '../../MyComponent/Navbar/Navbar';
import Visit_Request_Form from '../../MyComponent/Visit_Request_Form/Visit_Request_Form';
import Services_Detail from '../../MyComponent/Services_Detail/Services_Detail';
import Flash_dialogue from '../../MyComponent/Flash_dialogue/Flash_dialogue';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactStars from "react-rating-stars-component";

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaStar } from 'react-icons/fa';

import CheckIcon from '@mui/icons-material/Check';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import BoltIcon from '@mui/icons-material/Bolt';
import ChatIcon from '@mui/icons-material/Chat';

import Red_Police_Siren from '../../assets/Red_Police_Siren.png';
import newacologo from '../../assets/newacologo.png';
import playstore_icon from '../../assets/playstore_icon.png';
import Appstore_icon from '../../assets/Appstore_icon.png';
import mobile_screen_shot from '../../assets/mobile_screen_shot.png';

const Home = (props) => {
  const navigate = useNavigate();

  const [spinner, setSpinner] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [sender_email, setSender_email] = React.useState('admin@masclinicas.com');
  const [subject, setSubject] = React.useState('Request HEDIS Report');


  const [user_nme, setUser_nme] = React.useState('');
  const [member_id, setMember_id] = React.useState('');
  const [user_email, setUser_email] = React.useState('');
  const [role, setRole] = React.useState('');

  const [d_name, setD_name] = React.useState('');
  const [total_eligible, setTotal_eligible] = React.useState('');
  const [star_level, setStar_level] = React.useState('');
  const [colorectal_cancer, setColorectal_cancer] = React.useState('');
  const [breast_cancer, setBreast_cancer] = React.useState('');
  const [high_blood, setHigh_blood] = React.useState('');
  const [diabetes_blood_sugar, setDiabetes_blood_sugar] = React.useState('');

  const [worstmeasure1, setWorstmeasure1] = React.useState('');
  const [worstmeasure2, setWorstmeasure2] = React.useState('');
  const [worstmeasure3, setWorstmeasure3] = React.useState('');

  const [gapstarworst1, setGapstarworst1] = React.useState('');
  const [gapstarworst2, setGapstarworst2] = React.useState('');
  const [gapstarworst3, setGapstarworst3] = React.useState('');

  const [passingmeasure1, setPassingmeasure1] = React.useState('');
  const [passingmeasure2, setPassingmeasure2] = React.useState('');
  const [passingmeasure3, setPassingmeasure3] = React.useState('');

  const [passingpercent1, setPassingpercent1] = React.useState('');
  const [passingpercent2, setPassingpercent2] = React.useState('');
  const [passingpercent3, setPassingpercent3] = React.useState('');

  const [score, setScore] = React.useState('');
  const [money, setMoney] = React.useState('');
  const [stars_rating, setStars_rating] = React.useState('');

  const [kajksja, setKajksja] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  // Calculate the paginated data
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = kajksja.slice(indexOfFirstRow, indexOfLastRow);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const [visit_form, setVisit_Form] = React.useState(false);
  const [srvice_detail, setSrvice_detail] = React.useState(false);
  const [flash_form, setFlash_form] = React.useState(false);

  useEffect(() => {

    // User ID From LocalStorage
    const auth = localStorage.getItem("customer");
    const parsed = JSON.parse(auth);
    const us_name = parsed[0].name;
    const membe_id = parsed[0].member_id;
    const us_email = parsed[0].email;
    const role = parsed[0].role;

    setUser_nme(us_name)
    setMember_id(membe_id)
    setUser_email(us_email)
    setRole(role)


    if (membe_id) {
      const acp_new_data = localStorage.getItem('acp_new_data');
      if (acp_new_data) {
        const parsed_datta = JSON.parse(acp_new_data);

        let score = parsed_datta[0]['Score'];
        let stars_rating = parsed_datta[0]['STARS Rating'];

        setScore(score)
        setStars_rating(stars_rating)
        setKajksja(parsed_datta)
      }
      else {
        setSpinner(true)
        document.body.style.overflow = 'hidden';
      }


      // const acp_data = localStorage.getItem('acp_data');
      // if (acp_data) {
      //   const parsed_datta = JSON.parse(acp_data);

      //   let d_name = parsed_datta[0]['Doctor Name'];
      //   let total_eligible = parsed_datta[0]['Total Eligible'];

      //   let star_level = parsed_datta[0]['HEDIS Star Level'];
      //   let colorectal_cancer = parsed_datta[0]['Colorectal Cancer Screening'];
      //   let breast_cancer = parsed_datta[0]['Breast Cancer Screening'];
      //   let high_blood = parsed_datta[0]['Controlling High Blood Pressure'];
      //   let diabetes_blood_sugar = parsed_datta[0]['Diabetes Blood Sugar Controlled'];

      //   let worstmeasure1 = parsed_datta[0]['Worst Measure 1'];
      //   let worstmeasure2 = parsed_datta[0]['Worst Measure 2'];
      //   let worstmeasure3 = parsed_datta[0]['Worst Measure 3'];
      //   let gapstarworst1 = parsed_datta[0]['GAPS NEEDED for 5 STAR Worst 1'];
      //   let gapstarworst2 = parsed_datta[0]['GAPS NEEDED for 5 STAR Worst 2'];
      //   let gapstarworst3 = parsed_datta[0]['GAPS NEEDED for 5 STAR Worst 3'];
      //   let passingmeasure1 = parsed_datta[0]['Passing Measure 1'];
      //   let passingmeasure2 = parsed_datta[0]['Passing Measure 2'];
      //   let passingmeasure3 = parsed_datta[0]['Passing Measure 3'];
      //   let passingpercent1 = parsed_datta[0]['Passing Percent 1'];
      //   let passingpercent2 = parsed_datta[0]['Passing Percent 2'];
      //   let passingpercent3 = parsed_datta[0]['Passing Percent 3'];
      //   let score = parsed_datta[0]['Score'];
      //   let money = parsed_datta[0]['money'];

      //   setD_name(d_name)
      //   setTotal_eligible(total_eligible)
      //   setStar_level(star_level)
      //   setColorectal_cancer(colorectal_cancer)
      //   setBreast_cancer(breast_cancer)
      //   setHigh_blood(high_blood)
      //   setDiabetes_blood_sugar(diabetes_blood_sugar)

      //   setWorstmeasure1(worstmeasure1)
      //   setWorstmeasure2(worstmeasure2)
      //   setWorstmeasure3(worstmeasure3)

      //   setGapstarworst1(gapstarworst1)
      //   setGapstarworst2(gapstarworst2)
      //   setGapstarworst3(gapstarworst3)

      //   setPassingmeasure1(passingmeasure1)
      //   setPassingmeasure2(passingmeasure2)
      //   setPassingmeasure3(passingmeasure3)

      //   setPassingpercent1(passingpercent1)
      //   setPassingpercent2(passingpercent2)
      //   setPassingpercent3(passingpercent3)

      //   setScore(score)
      //   setMoney(money)
      // }
      // else {
      //   setSpinner(true)
      //   document.body.style.overflow = 'hidden';
      // }
    }
    /////////////


    new_get_dataa(membe_id)
    // get_dataa(membe_id)

  }, [])


  // For Scroll bottom to the top
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const shouldShowButton = scrollTop > 200;
      setShowScrollButton(shouldShowButton);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  /////////////////////////////




  const new_get_dataa = async (member_id) => {
    let uploaddata = new FormData();

    uploaddata.append('member_id', member_id);

    let api = Connection + 'rest_apis.php?action=get_new_data';

    try {
      const response = await fetch(api, {
        method: 'POST',
        headers: {
          // "Content-Type": "multipart/form-data",
        },
        body: uploaddata,
      });
      const jsonResponse = await response.json();

      let record4 = jsonResponse.response;
      console.log("jsonResponse =>", record4);

      if (record4 == 'fail') {
        setSpinner(false)
        document.body.style.overflow = 'auto';
        alert("Data not found.")
      }
      else {
        let score = record4[0]['Score'];
        let stars_rating = record4[0]['STARS Rating'];
        setScore(score)
        setStars_rating(stars_rating)

        setKajksja(record4)
        localStorage.setItem("acp_new_data", JSON.stringify(record4))

        setSpinner(false)
        document.body.style.overflow = 'auto';
      }
    } catch (error) {
      console.error(error);
      setSpinner(false)
      document.body.style.overflow = 'auto';
      alert("Data not found.")
      throw error; // rethrow the error to indicate failure
    }
  };



  const get_dataa = async (member_id) => {
    let uploaddata = new FormData();

    uploaddata.append('member_id', member_id);

    let api = Connection + 'rest_apis.php?action=get_dataa';

    try {
      const response = await fetch(api, {
        method: 'POST',
        headers: {
          // "Content-Type": "multipart/form-data",
        },
        body: uploaddata,
      });
      const jsonResponse = await response.json();

      let record4 = jsonResponse.response;
      console.log("jsonResponse =>", record4);

      if (record4 == 'fail') {
        setSpinner(false)
        document.body.style.overflow = 'auto';
        alert("Data not found.")
      }
      else {

        let id = record4[0]['id'];
        let member_id = record4[0]['member_id'];

        let d_name = record4[0]['Doctor Name'];
        let total_eligible = record4[0]['Total Eligible'];

        let star_level = record4[0]['HEDIS Star Level'];
        let colorectal_cancer = record4[0]['Colorectal Cancer Screening'];
        let breast_cancer = record4[0]['Breast Cancer Screening'];
        let high_blood = record4[0]['Controlling High Blood Pressure'];
        let diabetes_blood_sugar = record4[0]['Diabetes Blood Sugar Controlled'];

        let worstmeasure1 = record4[0]['Worst Measure 1'];
        let worstmeasure2 = record4[0]['Worst Measure 2'];
        let worstmeasure3 = record4[0]['Worst Measure 3'];
        let gapstarworst1 = record4[0]['GAPS NEEDED for 5 STAR Worst 1'];
        let gapstarworst2 = record4[0]['GAPS NEEDED for 5 STAR Worst 2'];
        let gapstarworst3 = record4[0]['GAPS NEEDED for 5 STAR Worst 3'];
        let passingmeasure1 = record4[0]['Passing Measure 1'];
        let passingmeasure2 = record4[0]['Passing Measure 2'];
        let passingmeasure3 = record4[0]['Passing Measure 3'];
        let passingpercent1 = record4[0]['Passing Percent 1'];
        let passingpercent2 = record4[0]['Passing Percent 2'];
        let passingpercent3 = record4[0]['Passing Percent 3'];
        let score = record4[0]['Score'];
        let money = record4[0]['money'];

        setD_name(d_name)
        setTotal_eligible(total_eligible)
        setStar_level(star_level)
        setColorectal_cancer(colorectal_cancer)
        setBreast_cancer(breast_cancer)
        setHigh_blood(high_blood)
        setDiabetes_blood_sugar(diabetes_blood_sugar)

        setWorstmeasure1(worstmeasure1)
        setWorstmeasure2(worstmeasure2)
        setWorstmeasure3(worstmeasure3)

        setGapstarworst1(gapstarworst1)
        setGapstarworst2(gapstarworst2)
        setGapstarworst3(gapstarworst3)

        setPassingmeasure1(passingmeasure1)
        setPassingmeasure2(passingmeasure2)
        setPassingmeasure3(passingmeasure3)

        setPassingpercent1(passingpercent1)
        setPassingpercent2(passingpercent2)
        setPassingpercent3(passingpercent3)

        setScore(score)
        setMoney(money)



        localStorage.setItem("acp_data", JSON.stringify(record4))

        setSpinner(false)
        document.body.style.overflow = 'auto';
      }
    } catch (error) {
      console.error(error);
      setSpinner(false)
      document.body.style.overflow = 'auto';
      alert("Data not found.")
      throw error; // rethrow the error to indicate failure
    }
  };



  const goonlinkapp = (link) => {
    window.open(link, '_blank');
  }



  const MyCircularProgress = ({ percentage }) => {
    const MAX_POINTS = 100; // Or any other maximum value you have

    return (
      <div style={{ position: 'relative', width: 185, margin: '10px auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <CircularProgressbar
            value={(percentage / 5) * 100}
            // text={`${Math.round((MAX_POINTS * percentage) / 100)}%`}
            styles={buildStyles({
              strokeLinecap: 'round',
              textColor: 'black',
              pathColor: '#d29fb2',
              trailColor: 'lightgray',
              textSize: '24px',
            })}
          />

          <div style={{ width: '100%', height: '100%', position: 'absolute', top: '0%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ fontSize: '15px', color: 'black' }}>Stars Rating</div>

            <div style={{ fontSize: '50px', fontWeight: 'bold', color: 'black' }}>
              {/* {Math.round((MAX_POINTS * percentage) / 100)}% */}{percentage}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
              <ReactStars
                count={5}
                value={stars_rating}
                size={24}
                edit={false}
                activeColor="#00a0f8"
              />
              {/* <div style={{ width: 23, height: 23, backgroundColor: '#00a0f8', borderRadius: '50%', marginTop: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FaStar color="lightgray" size={13} />
              </div>

              <div style={{ width: 23, height: 23, backgroundColor: '#00a0f8', borderRadius: '50%', marginTop: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FaStar color="lightgray" size={13} />
              </div> */}
            </div>
          </div>

        </div>
      </div>
    );
  };


  const send_email = () => {
    setLoader(true)

    let uploaddata = new FormData();

    let receiver_email = "admin@acoprofessional.com"
    let message = `This is an email notification of a request for the full HEDIS Report from ${user_nme}<br><hr/>Details<br>Name: ${user_nme}<br>Email: ${user_email}<br>Member ID: ${member_id}<br>`;

    console.log("embbbbbbbbbbbbbbbbbbbbbbbail =>", receiver_email)
    console.log("embbbbbbbbbbbbbbbbbbbbbbbail =>", sender_email)
    console.log("embbbbbbbbbbbbbbbbbbbbbbbail =>", subject)
    console.log("embbbbbbbbbbbbbbbbbbbbbbbail =>", message)


    uploaddata.append('sender_email', sender_email);
    uploaddata.append('receiver_email', receiver_email);
    uploaddata.append('subject', subject);
    uploaddata.append('message', message);

    let api = 'https://masclinicas.com//massclinicslatam/send_email.php?action=send_any_email';
    console.log("pass => ", api)

    fetch(api, {
      method: 'POST',
      headers: {
        // "Content-Type": "multipart/form-data",
        // "otherHeader": "foo",
      },
      body: uploaddata,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Response: ", response.response)
        toast.success('Successfully sent an Email');
        setLoader(false)
      })
      .catch((error) => {
        console.error("Error: ", error);
        alert("Something went wrong.")
        setLoader(false)
      });
  }


  const openflashdialog = () => {
    document.body.style.overflow = 'hidden';
    setFlash_form(true)
  }

  const closeflashdialog = () => {
    document.body.style.overflow = 'auto';
    setFlash_form(false)
  }

  const handleOpen = () => {
    document.body.style.overflow = 'hidden';
    setVisit_Form(true);
  };

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    setVisit_Form(false);
  };


  const serviceOpen = () => {
    document.body.style.overflow = 'hidden';
    setSrvice_detail(true);
  };

  const serviceClose = () => {
    document.body.style.overflow = 'auto';
    setSrvice_detail(false);
  };


  const chat_romm = async () => {
    if (role == 'admin') {
      const url = `/Chat`;
      navigate(url);
    }
    else {
      const check_chat = 'do_chat'
      const url = `/Chat?check_chat=${check_chat}&chat_name=${"John Smith"}&receiver_id=${"1"}`;
      navigate(url);
    }
  }

  const opeefoterlink = (val) => {
    if (val == 'aco') {
      window.open("http://www.acoprofessional.com", "_blank");
    }
    else if (val == 'plat') {
      window.open("http://www.platinumipa.com", "_blank");
    }
    else if (val == 'leet') {
      window.open("http://www.leetris.com", "_blank");
    }
    else if (val == 'detail') {
      window.open("https://link.apisystem.tech/widget/form/JjpIsNzJJH141Jr1fxXn", "_blank");
    }
    else { }
  }

  return (
    <div className="medical_website">
      <Navbar />

      <header className='top_section'>
        <div className='logo_nd_name'>
          <img className="image_logo" src={newacologo} />
        </div>

        {role == 'admin' ?
          <div onClick={() => chat_romm()} style={styles.buttonPrimary}>
            <p style={{ color: 'white', fontWeight: 'bold', fontSize: 13 }}>All CHAT</p>
          </div>
          :
          <div className='mainofchat'>
            <div></div>
            <div onClick={() => chat_romm()} className='chatfromhome_btn'>
              <ChatIcon />
              {role == 'admin' ?
                <p>All CHAT</p>
                :
                <p>CHAT WITH US</p>
              }
            </div>
          </div>
        }

        {role !== 'admin' &&
          <MyCircularProgress percentage={stars_rating} />
        }
      </header>



      {role !== 'admin' &&
        <>
          {/* <div style={styles.buttonPrimary} onClick={openflashdialog}>
            <p style={{ color: 'white', fontWeight: 'bold', fontSize: 13 }}>FLASH REWARD BONUS</p>
          </div> */}

          {loader ?
            <div style={styles.buttonSecondary}>
              <PulseLoader
                color={"white"}
                loading={loader}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
            :
            <div style={styles.buttonSecondary} onClick={send_email}>
              <p style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }}>Request full HEDIS Report</p>
            </div>
          }
        </>
      }

      {/* <p className='headerofview'>HEDIS Summary</p>
      <div style={styles.summaryContainer}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={styles.summarySTARText}>⦁ HEDIS STAR Level - <p id='percentdark'>{star_level}</p></p>
          <p style={styles.summaryText}>⦁ Total Eligible - <p id='percentdark'>{total_eligible}</p></p>
          <p style={styles.summaryText}>⦁ Colorectal Cancer Screening - <p id='percentdark'>{colorectal_cancer}</p></p>
          <p style={styles.summaryText}>⦁ Breast Cancer Screening - <p id='percentdark'>{breast_cancer}</p></p>
          <p style={styles.summaryText}>⦁ High Blood Pressure - <p id='percentdark'>{high_blood}</p></p>
          <p style={styles.summaryText}>⦁ Diabetes Blood Sugar Controlled - <p id='percentdark'>{diabetes_blood_sugar}</p></p>
        </div>

        <div onClick={() => chat_romm()} className='chatfromhome_btn'>
          <ChatIcon />
          {role == 'admin' ?
            <p>All CHAT</p>
            :
            <p>CHAT WITH US</p>
          }
        </div>

        <div className='forspacing'></div>
      </div> */}


      {role !== 'admin' &&
        <>
          {member_id === '' || member_id === null ? null :
            <>
              <p className='headerofview'>ACO Services</p>
              <div style={styles.container}>
                <div style={styles.card}>
                  <p style={styles.title}>Submit Patient Data</p>
                  <p style={styles.subtitle}>Increase your HEDIS score.</p>

                  <div style={styles.button} onClick={() => opeefoterlink('detail')}>
                    View Details
                  </div>
                </div>

                <div style={styles.card}>
                  <p style={styles.title}>In Office HEDIS Review</p>
                  <p style={styles.subtitle}>We'll Bring Lunch :)</p>

                  <div style={styles.button} onClick={() => handleOpen()}>
                    View Details
                  </div>
                </div>
              </div>
            </>
          }
        </>
      }



      {role !== 'admin' &&
        <div className="table-container">
          <div className="table-wrapper">
            <div className="table-header">
              <div id='headername' className='first_row'>Measure</div>
              {/* <div id='headername'>Numerator</div>
            <div id='headername'>Denominator</div>
            <div id='headername'>Rate</div>
            <div id='headername'>Weight</div>
            <div id='headername'>2 STAR</div>
            <div id='headername'>3 STAR</div>
            <div id='headername'>4 STAR</div>
            <div id='headername'>5 STAR</div>
            <div id='headername'>4 STARS</div>
            <div id='headername'>5 STARS</div> */}
              <div id='headername'>Score</div>
            </div>
            {kajksja.map((item, index) => (
              <div key={index} className="table-row">
                <div className='first_row'>{item.Measure}</div>
                {/* <div>{item.Numerator}</div>
              <div>{item.Denominator}</div> */}
                <div>{item.Rate}</div>
                {/* <div>{item.Weight}</div>
              <div>{item["2 STAR"]}</div>
              <div>{item["3 STAR"]}</div>
              <div>{item["4 STAR"]}</div>
              <div>{item["5 STAR"]}</div>
              <div>{item["4 STARS"]}</div>
              <div>{item["5 STARS"]}</div>
              <div>{item.Score}</div> */}
              </div>
            ))}
          </div>
          {/* <div className="pagination">
          {Array.from({ length: Math.ceil(kajksja.length / rowsPerPage) }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div> */}
        </div>
      }


      {/* <div className='containe1'>
        <div className='cad1'>
          <p className='tit1e'><img className='iconstrtsalert' src={Red_Police_Siren} /> NEEEDS IMMEDIATE ATTENTION <img className='iconsalert' src={Red_Police_Siren} /></p>
          <p className='fstsubtile'>1. {worstmeasure1} is your worst measure. You need {gapstarworst1} more completed GAPS to achieve a 5 Star Rating in this category!</p>
          <p className='othrsubtile'>2. {worstmeasure2} is your 2nd worst measure. You need {gapstarworst2} more completed GAPS to achieve a 5 Star Rating in this category!</p>
          <p className='othrsubtile'>3. {worstmeasure3} is your 3rd worst measure. You need {gapstarworst3} more completed GAPS to achieve a 5 Star Rating in this category!</p>
        </div>

        <div className='cad2'>
          <p className='tit1e'>MY TOP PASSING MEASURES</p>
          <p className='fstsubtile'>1. {passingmeasure1} - {passingpercent1}%</p>
          <p className='othrsubtile'>2. {passingmeasure2} - {passingpercent2}%</p>
          <p className='othrsubtile'>3. {passingmeasure3} - {passingpercent3}%</p>
        </div>
      </div> */}



      <hr style={{ width: '100%', marginTop: 30, marginBottom: 50 }} />


      <section className='playstore_appsign'>
        <img className="image_playstore" src={mobile_screen_shot} />

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div className='name_ty_logo'>
            <div className='circule_for_logo'>
              <img className="imf_log" src={newacologo} />
            </div>
            <h4>ACO Professional</h4>
          </div>

          <div className='twoo_three_divs'>
            <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
            <h5>Download the app</h5>
          </div>

          <div className='twoo_three_divs'>
            <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
            <h5>See HEDIS Summary</h5>
          </div>

          <div className='twoo_three_divs'>
            <CheckIcon style={{ color: '#014e78', fontSize: 15 }} />
            <h5>ACO Services</h5>
          </div>

          <div className='two_btn_goplay'>
            <div className='PlayStore'>
              <img className="playstore_img" src={playstore_icon} />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                <h6>GET IT ON</h6>
                <h4>Google Play</h4>
              </div>
            </div>

            <div className='PlayStore'>
              <img className="playstore_img" src={Appstore_icon} />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                <h6>Available on the</h6>
                <h4>App Store</h4>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className='footer_top'></section>
      <div className="footer_second_end">
        <div className="col-12 col-sm-12 col-lg-6 mb-4 order-0 order-sm-0"><a className="text-decoration-none" href="#"><img src="assets/img/gallery/footer-logo.png" height="51" alt="" /></a>
          <img className="p_app_name" src={newacologo} />
        </div>

        <div className='footor_inside'>
          <div className="col-6 col-sm-4 col-lg-2 mb-3 order-2 order-sm-1">
            <h5 className="Departments">HEDIS Services</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="footer_link" href="#!">Submit GAP Data</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Request Reporting</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Request In-person Review</a></li>
            </ul>
          </div>

          <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
            <h5 className="Departments">Resources</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><div className="footer_link" href="#!" onClick={() => opeefoterlink('aco')}>ACO Professional</div></li>
              <li className="lh-lg"><div className="footer_link" href="#!" onClick={() => opeefoterlink('plat')}>Platinum IPA</div></li>
              <li className="lh-lg"><div className="footer_link" href="#!" onClick={() => opeefoterlink('leet')}>Leetris Systems</div></li>
            </ul>
          </div>

          <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
            <h5 className="Departments"> Customer Care</h5>
            <ul className="list-unstyled mb-md-4 mb-lg-0">
              <li className="lh-lg"><a className="footer_link" href="#!">About Us</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Contact US</a></li>
              <li className="lh-lg"><a className="footer_link" href="#!">Chat with US</a></li>
            </ul>
          </div>
        </div>

      </div>


      <section className='footer_end'>
        <p>ACO Professional, 2024. A Leetris Systems Company.</p>
      </section>

      {showScrollButton &&
        <button onClick={scrollToTop} className="scroll_top_button">
          <ArrowUpwardIcon />
        </button>
      }


      {visit_form == true &&
        <Visit_Request_Form visit_form={visit_form} handleClose={handleClose}>
          <p>Modal Content Goes Here</p>
        </Visit_Request_Form>
      }


      {srvice_detail == true &&
        <Services_Detail srvice_detail={srvice_detail} serviceClose={serviceClose}>
          <p>Modal Content Goes Here</p>
        </Services_Detail>
      }

      {flash_form == true &&
        <Flash_dialogue flash_form={flash_form} handleClose={closeflashdialog}>
          <p>Modal Content Goes Here</p>
        </Flash_dialogue>
      }


      {spinner && (
        <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(2, 2, 2, 0.8)', position: 'absolute', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <div style={{ display: 'flex', width: '30%', backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '30px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: '6px', }}>
            <PulseLoader
              color={"#00a0f8"}
              loading={spinner}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p style={{ fontSize: '16px', color: '#00a0f8', fontWeight: 'bold', marginLeft: '15px' }}>
              Progressing your request
            </p>
          </div>
        </div>
      )}

      <ToastContainer />

    </div >
  );
}


const styles = {
  buttonPrimary: {
    width: '80%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '10px 12px',
    backgroundColor: '#d29fb2',
    marginTop: 20,
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'center'
  },
  buttonSecondary: {
    width: '80%',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '10px 12px',
    backgroundColor: '#00a0f8',
    marginTop: 10,
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'center',
    marginBottom: 20
  },
  summaryContainer: {
    width: '90%',
    padding: '10px',
    margin: '2px auto 10px auto',
    borderRadius: 5,
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.3)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  summaryText: {
    color: 'black',
    fontSize: 14,
    margin: '3px 10px',
    display: 'flex',
  },
  summarySTARText: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    margin: '3px 10px',
    display: 'flex',
  },



  container: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  card: {
    width: '48%',
    backgroundColor: 'white',
    borderRadius: 10,
    borderWidth: '0.5px',
    borderColor: 'lightgray',
    padding: 12,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.3)',
    marginLeft: 5,
  },
  title: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 13,
    marginTop: 3,
  },
  subtitle: {
    color: '#d29fb2',
    fontWeight: 500,
    fontSize: 11,
  },
  button: {
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 10,
    padding: '8px 0',
    backgroundColor: '#d29fb2',
    color: 'white',
    textAlign: 'center',
    cursor: 'pointer',
  }
};


export default Home;