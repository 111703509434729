import React, { useState, useEffect, useMemo, useContext } from 'react'
import './Button.css';
import { Link } from 'react-router-dom';
import { GlobalInfo } from "../../../App"
import Connection from '../../../connection';

export function Button() {
  const { loginenter } = useContext(GlobalInfo)

  const logout = async (e) => {
    document.body.style.overflow = 'auto';
    console.log("logout")

    localStorage.removeItem("customer");
    loginenter()
  }

  return (
    <Link to='log-out'>
      <button onClick={logout} className='btn'>Log Out</button>
    </Link>
  );
}
